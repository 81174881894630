import React, { Fragment } from "react";
import { Typography } from "@shared/ui/Typography";
import { useTranslation } from "next-i18next";
import Styled from "./styled";

export function StepsToTakeCredit() {
  const { t } = useTranslation();

  const steps = [
    {
      id: 0,
      title: t(`Подайте заявку`),
      description: t(
        `Оставьте заявку онлайн на сайте. Это займет не более 2 минут.`
      ),
    },
    {
      id: 1,
      title: t(`Дождитесь решения`),
      description: t(
        `Мы рассмотрим вашу заявку в течение 20 минут и с вами свяжется наш менеджер.`
      ),
    },
    {
      id: 2,
      title: t(`Получите деньги`),
      description: t(
        `После одобрения получите деньги любым удобным для вас способом.`
      ),
    },
  ];

  return (
    <section id="steps-to-take-credit">
      <Styled.Content>
        <Styled.Title tag="h2" fontSize="4rem" weight={700} lineHeight={130}>
          {t(`Как получить кредит?`)}
        </Styled.Title>
        <Styled.Steps>
          {steps.map((step, index) => (
            <Fragment key={step.id}>
              <Styled.Step>
                <Styled.StepNumber>
                  <Typography
                    fontSize="2.4rem"
                    tag="span"
                    color="basic_white"
                    weight={500}
                  >
                    {index + 1}
                  </Typography>
                </Styled.StepNumber>
                <Styled.StepTitle tag="h3" fontSize="2.4rem" weight={500}>
                  {step.title}
                </Styled.StepTitle>
                <Typography tag="p" fontSize="1.6rem">
                  {step.description}
                </Typography>
              </Styled.Step>
              {index < steps.length - 1 && (
                <Styled.StepDivider color="primary_light_gray" />
              )}
            </Fragment>
          ))}
        </Styled.Steps>
      </Styled.Content>
    </section>
  );
}
