import styled from "styled-components";
import { motion } from "framer-motion";
import Image from "next/image";
import { Typography } from "@shared/ui/Typography";
import { Container } from "@shared/ui/sharedstyles";

const View = styled.div``;

const Buttons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 1.8rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 4rem;
  right: 3rem;
  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: none;
  }
`;
const NavButton = styled.button`
  &:hover,
  :active {
    svg {
      path {
        fill: ${(props) => props.theme.colors.primary_red};
      }
    }
  }
`;
const Empbla = styled.div`
  overflow: hidden;
`;
const EmpblaViewport = styled.div`
  position: relative;
  &:hover {
    ${Buttons} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;
const EmpblaContainer = styled.div`
  display: flex;
`;
const Slide = styled.div`
  flex: 0 0 100%;
  position: relative;
  aspect-ratio: 16/7;
  overflow: hidden;

  @media screen and (max-width: ${(props) => props.theme.sizes.mobile}) {
    aspect-ratio: auto;
    height: calc(100vh - 5.6rem * 2.5);
  }
`;
const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);

  @media screen and (max-width: ${(props) => props.theme.sizes.mobile}) {
    max-width: initial;
    max-height: 100%;
    object-position: 55% 50%;
  }
`;
const RightImageContainer = styled.div`
  position: absolute;
  display: grid;
  grid-auto-flow: row;
  justify-content: flex-end;
  grid-template-rows: auto auto;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: auto;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: none;
  }
`;
const RightImageWrapper = styled(motion.div)`
  width: auto;
  height: 100%;
  display: grid;
  justify-content: flex-end;
`;
const RightImage = styled(Image)`
  width: auto;
  height: 100%;
`;
const Promo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;
const SContainer = styled(Container)`
  display: grid;
  align-content: center;
  justify-items: flex-start;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    text-align: center;
    justify-items: center;
  }
`;
const Title = styled(Typography)`
  margin-bottom: 2.4rem;
  max-width: 68.6rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    margin-bottom: 1.6rem;
  }
`;
const Subtitle = styled(Typography)`
  margin-bottom: 4rem;
  max-width: 68.6rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    margin-bottom: 3.2rem;
  }
`;
const Navigation = styled.div`
  position: absolute;
  bottom: 1.6rem;
  left: 0;
  right: 0;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1.2rem;
`;
const Dot = styled(motion.div)<{ selected: boolean }>`
  width: ${(props) => (props.selected ? "1.1rem" : "0.8rem")};
  height: ${(props) => (props.selected ? "1.1rem" : "0.8rem")};
  background-color: ${(props) =>
    props.selected ? "transparent" : props.theme.colors.basic_white};
  cursor: pointer;
  border: 3px solid ${(props) => props.theme.colors.basic_white};
  border-radius: 50%;
`;

export default {
  View,
  Buttons,
  Navigation,
  Dot,
  NavButton,
  SContainer,
  EmpblaContainer,
  RightImageContainer,
  RightImage,
  RightImageWrapper,
  Subtitle,
  BackgroundImage,
  Title,
  Slide,
  Promo,
  Empbla,
  EmpblaViewport,
};
