import styled from "styled-components";
import { Container } from "@shared/ui/sharedstyles";
import { Typography } from "@shared/ui/Typography";
import { Divider } from "@shared/ui/Divider";

const Content = styled(Container)`
  padding-block: 8.4rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    padding-block: 6.4rem 5.6rem;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 7.8rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    margin-bottom: 4rem;
  }
`;

const Steps = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-gap: 1.8rem;
  }
`;

const Step = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  text-align: center;
  align-content: flex-start;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    padding: 1.95rem;
  }
`;

const StepNumber = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary_red};
  box-shadow: ${(props) => props.theme.shadow["20dp"]};
  border-radius: 0 0.8rem;
  margin-bottom: 3.2rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    > span {
      font-size: 2.4rem;
    }
  }
`;

const StepTitle = styled(Typography)`
  margin-bottom: 1.6rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    font-size: 2.4rem;
  }
`;

const StepDivider = styled(Divider)`
  width: 28rem;
  margin-inline: -8.8rem;
  align-self: flex-start;
  margin-top: 3.2rem;
  opacity: 0.5;
  justify-self: center;
  z-index: -1;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    display: none;
  }
`;

export default {
  Content,
  Title,

  Steps,
  Step,
  StepNumber,
  StepTitle,
  StepDivider,
};
