import Head from "next/head";
import { Products } from "@features/Products";
import { StepsToTakeCredit } from "@features/StepsToTakeCredit";
import { CalculatorBlock } from "@features/CalculatorBlock";
import { AppDownloadBlock } from "@features/AppDownloadBlock";
import { Layout } from "@features/Layout";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { CarouselMainBlock } from "@features/CarouselMainBlock";
import { routePaths } from "@shared/routes";
import { useTranslation } from "next-i18next";
import { axiosInstance } from "@shared/api";
import { IMainPage } from "@shared/types";

interface IProps {
  data: IMainPage;
}

export default function Index(props: IProps) {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t(`БНК Финанс Казахстан`)}</title>
        <meta name="description" content={t("meta-description")} />
        <meta property="og:site_name" content={t(`БНК Финанс Казахстан`)} />
        <meta property="og:title" content={t(`БНК Финанс Казахстан`)} />
        <meta property="og:description" content={t("meta-description")} />
      </Head>
      <CarouselMainBlock slides={props.data.slides} />
      <StepsToTakeCredit />
      <Products products={props.data.products} />
      <CalculatorBlock
        href={routePaths.main.products.request}
        properties={props.data.calculators}
      />
      <AppDownloadBlock />
    </>
  );
}

Index.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

export async function getServerSideProps({ locale }) {
  const response = await axiosInstance.get(`/main-page`, {
    headers: { "Accept-Language": locale },
  });

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      data: response.data,
    },
  };
}
