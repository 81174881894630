import React from "react";
import BgImage from "public/static/features/app-download/bg-image.png";
import PhoneImage from "public/static/features/app-download/phone-image.png";
import QrImage from "public/static/features/app-download/qr.svg";
import AppStoreImage from "public/static/features/app-download/app-store.svg";
import GooglePlayImage from "public/static/features/app-download/google-play.svg";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Styled from "./styled";

export function AppDownloadBlock() {
  const { t } = useTranslation();

  return (
    <Styled.Section>
      <Styled.Content>
        <Styled.Wrapper>
          <Styled.Title fontSize="4rem" tag="h2" weight={700}>
            {t(`Мобильное приложение`)}
          </Styled.Title>
          <Styled.Subtitle tag="p">
            {t(`Отсканируйте QR-код, чтобы скачать приложение`)}
          </Styled.Subtitle>
          <Styled.QrImage src={QrImage} alt="qr" />
          <Styled.Buttons>
            <Link
              href="https://apps.apple.com/kz/app/bnkfinance-kz/id1660975129"
              target="_blank"
            >
              <Image src={AppStoreImage} alt="app-store" />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=kz.bnk"
              target="_blank"
            >
              <Image src={GooglePlayImage} alt="google-play" />
            </Link>
          </Styled.Buttons>
        </Styled.Wrapper>
        <Styled.BgImage src={BgImage} alt="bg-image" />
        <Styled.PhoneImage quality={100} src={PhoneImage} alt="bg-image" />
      </Styled.Content>
    </Styled.Section>
  );
}
