import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { Container } from "@shared/ui/sharedstyles";
import React from "react";
import { Calculator } from "@features/Calculator";
import { useTranslation } from "next-i18next";
import { ICalculatorMain } from "@shared/types";

interface IProps {
  href: string;
  openContactModal?: () => void;
  properties: ICalculatorMain[];
}

export function CalculatorBlock(props: IProps) {
  const { t } = useTranslation();

  if (!props.properties.length) {
    return null;
  }

  return (
    <StyledContainer id="calculator">
      <StyledTitle tag="h2" fontSize="4rem" weight={700}>
        {t(`Калькулятор`)}
      </StyledTitle>
      {props.properties.map((calc) => (
        <Calculator
          key={calc.id}
          initSum={Number(calc.loan_amount.init)}
          minSum={Number(calc.loan_amount.min)}
          maxSum={Number(calc.loan_amount.max)}
          stepSum={Number(calc.loan_amount.step)}
          fromSum={t(`от {{fromSum}} тыс.`, {
            fromSum: Number(Number(calc.loan_amount.min) / 1_000),
          })}
          toSum={t(`до {{toSum}} млн.`, {
            toSum: Number(calc.loan_amount.max) / 1_000_000,
          })}
          initTerm={Number(calc.loan_term.init)}
          minTerm={Number(calc.loan_term.min)}
          maxTerm={Number(calc.loan_term.max)}
          stepTerm={Number(calc.loan_term.step)}
          fromTerm={t(`от {{fromTerm}} мес.`, {
            fromTerm: Number(calc.loan_term.min),
          })}
          toTerm={t(`до {{toTerm}} лет`, {
            toTerm: Math.round(Number(calc.loan_term.max) / 12),
          })}
          rate={calc.loan_rate}
          openContactModal={props.openContactModal}
          href={props.href}
        />
      ))}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 8.4rem;
  padding-bottom: 14.8rem;
  position: relative;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    padding-top: 6.4rem;
    padding-bottom: 0;
  }
`;

const StyledTitle = styled(Typography)`
  margin-bottom: 1.8rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    margin-bottom: 4rem;
  }
`;
