import styled from "styled-components";
import { Container } from "@shared/ui/sharedstyles";
import { Typography } from "@shared/ui/Typography";

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.primary_light_gray}1A;
`;

const Content = styled(Container)`
  padding-top: 8.4rem;
  padding-bottom: 8.4rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 6.4rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    margin-bottom: 4rem;
  }
`;

const Products = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 3.2rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-auto-flow: row;
    grid-gap: 4rem;
  }
`;

export default {
  Section,
  Content,
  Title,

  Products,
};
