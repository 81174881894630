import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { Container } from "@shared/ui/sharedstyles";
import Image from "next/image";

const Section = styled(Container)`
  padding-block: 3.2rem 8.6rem;
  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    padding-block: 6.4rem;
  }
`;

const Content = styled(Container)`
  position: relative;
  background-color: ${(props) => props.theme.colors.primary_light_gray}1A;
  border-radius: 1.2rem;
  overflow: hidden;
  padding: 0;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: block;
    padding: 0;
  }
`;

const Wrapper = styled.div`
  padding: 6.4rem 9.6rem;
  position: relative;
  min-height: 46.6rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    padding: 4rem 1.6rem 44rem 1.6rem;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 1.6rem;
`;

const Subtitle = styled(Typography)`
  margin-bottom: 4rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: none;
  }
`;

const QrImage = styled(Image)`
  display: block;
  margin-bottom: 4rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: none;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 1.6rem;
`;

const BgImage = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -1;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: none;
  }
`;

const PhoneImage = styled(Image)`
  position: absolute;
  right: 0;
  bottom: 0;
  min-height: 100%;
  z-index: -1;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    min-height: auto;
  }

  @media screen and (max-width: ${(props) => props.theme.sizes.mobile}) {
    right: -10rem;
  }
`;

export default {
  Section,
  Content,
  Wrapper,
  Title,
  Subtitle,
  QrImage,
  Buttons,
  BgImage,
  PhoneImage,
};
