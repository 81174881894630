import React from "react";
import { useTranslation } from "next-i18next";
import { IMainPageProduct } from "@shared/types";
import Styled from "./styled";
import { ProductCard } from "./ui";

interface IProps {
  products: IMainPageProduct[];
}

export function Products(props: IProps) {
  const { t } = useTranslation();

  return (
    <Styled.Section>
      <Styled.Content>
        <Styled.Title tag="h2" fontSize="4rem" weight={700}>
          {t(`Продукты`)}
        </Styled.Title>
        <Styled.Products>
          {props.products.map((product) => (
            <ProductCard key={product.id} {...product} />
          ))}
        </Styled.Products>
      </Styled.Content>
    </Styled.Section>
  );
}
