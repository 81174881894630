import React from "react";
import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import Image from "next/image";
import { motion } from "framer-motion";
import { Button } from "@shared/ui/Button";
import { IMainPageProduct } from "@shared/types";
import Link from "next/link";

export function ProductCard(props: IMainPageProduct) {
  return (
    <StyledView>
      <StyledImageWrapper>
        <StyledImage
          alt={props.title}
          quality={95}
          width={352}
          height={239}
          src={props.url}
        />
      </StyledImageWrapper>
      <StyledInfo>
        <StyledTitle tag="h4" fontSize="2.4rem" weight={700}>
          {props.title}
        </StyledTitle>
        {Boolean(props.tags?.length) && (
          <StyledPills>
            {props.tags.map((feature) => (
              <StyledPill key={feature}>
                <Typography
                  fontSize="1.4rem"
                  weight={400}
                  color="primary_light_gray"
                >
                  {feature}
                </Typography>
              </StyledPill>
            ))}
          </StyledPills>
        )}
        <StyledList
          fontSize="1.6rem"
          weight={400}
          tag="div"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </StyledInfo>
      <Link href={props.button_url}>
        <StyledButton>{props.button_text}</StyledButton>
      </Link>
    </StyledView>
  );
}

const StyledImage = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const StyledView = styled(motion.div)`
  overflow: hidden;
  display: grid;
  max-width: 35.2rem;
  grid-template-rows: auto 1fr auto;
  grid-gap: 3.2rem;
`;

const StyledImageWrapper = styled.div`
  border-radius: 0.8rem;
  aspect-ratio: 3 / 2;
  overflow: hidden;
`;

const StyledInfo = styled.div`
  align-content: flex-start;
`;

const StyledTitle = styled(Typography)`
  margin-bottom: 1.6rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    font-size: 2.4rem;
  }
`;

const StyledList = styled(Typography)`
  > ul {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1.6rem;
    list-style: disc;
    padding-left: 2.4rem;
    line-height: 2rem;

    @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
      padding-left: 1.6rem;
    }
  }
`;

const StyledPills = styled.ul`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.8rem;
  column-gap: 0.8rem;
  margin-bottom: 1.6rem;
  justify-content: flex-start;
  padding: 0;
`;

const StyledPill = styled.li`
  border-radius: 0.4rem;
  border: 1px solid ${(props) => props.theme.colors.primary_light_gray};
  padding: 0.4rem 0.8rem;
`;

const StyledButton = styled(Button)`
  justify-self: flex-start;
`;
