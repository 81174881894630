import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { LeftCarousel, RightCarousel } from "public/static/icons";
import RightUpImage from "public/static/main/carousel/right-up.png";
import RightBottomImage from "public/static/main/carousel/right-bottom.png";
import { Button } from "@shared/ui/Button";
import Link from "next/link";
import Autoplay from "embla-carousel-autoplay";
import { IBanner } from "@shared/types";
import Styled from "./styled";

interface IProps {
  slides: IBanner[];
}

export function CarouselMainBlock(props: IProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, skipSnaps: false },
    [Autoplay({ delay: 5000, stopOnInteraction: false })]
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      emblaApi && emblaApi.scrollTo(Number(e.currentTarget.dataset.value)),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  const scrollPrev = () => {
    if (emblaApi) emblaApi.scrollPrev();
  };

  const scrollNext = () => {
    if (emblaApi) emblaApi.scrollNext();
  };

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <Styled.View>
      <Styled.Empbla>
        <Styled.EmpblaViewport ref={emblaRef}>
          <Styled.EmpblaContainer>
            {props.slides.map((slide) => (
              <Styled.Slide key={slide.id}>
                <Styled.BackgroundImage
                  quality={90}
                  priority
                  width="1440"
                  height="576"
                  src={slide.url}
                  alt={slide.title}
                />
                <Styled.Promo>
                  <Styled.SContainer>
                    <Styled.Title
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1 }}
                      tag="h2"
                      fontSize="4.8rem"
                      weight={700}
                      color="basic_white"
                    >
                      {slide.title}
                    </Styled.Title>
                    <Styled.Subtitle
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1, delay: 0.3 }}
                      fontSize="2rem"
                      tag="span"
                      color="basic_white"
                      dangerouslySetInnerHTML={{ __html: slide.content }}
                    />
                    <Link href={slide.button_url}>
                      <Button
                        initial={{ opacity: 0, x: -50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1, delay: 0.6 }}
                      >
                        {slide.button_text}
                      </Button>
                    </Link>
                  </Styled.SContainer>
                </Styled.Promo>
              </Styled.Slide>
            ))}
          </Styled.EmpblaContainer>
          <Styled.RightImageContainer>
            <Styled.RightImageWrapper
              initial={{ opacity: 0.8, y: -150 }}
              animate={{ opacity: 0.8, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Styled.RightImage src={RightUpImage} alt="logo-top" />
            </Styled.RightImageWrapper>
            <Styled.RightImageWrapper
              initial={{ opacity: 0.8, y: 150 }}
              animate={{ opacity: 0.8, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Styled.RightImage src={RightBottomImage} alt="logo-bottom" />
            </Styled.RightImageWrapper>
          </Styled.RightImageContainer>
          <Styled.Buttons>
            <Styled.NavButton type="button" onClick={scrollPrev}>
              <LeftCarousel />
            </Styled.NavButton>
            <Styled.NavButton type="button" onClick={scrollNext}>
              <RightCarousel />
            </Styled.NavButton>
          </Styled.Buttons>
          <Styled.Navigation>
            {props.slides.map((slide, index) => (
              <Styled.Dot
                onClick={scrollTo}
                key={slide.id}
                data-value={index}
                initial={{ opacity: 1 }}
                selected={index === selectedIndex}
              />
            ))}
          </Styled.Navigation>
        </Styled.EmpblaViewport>
      </Styled.Empbla>
    </Styled.View>
  );
}
